import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import SimpleInterest from './SimpleInterest';

function App() {
  return (
    // BrowserRouter to wrap all
    // the other components
    <BrowserRouter>
      {/*switch used to render only the first
    route that matches the location rather
    than rendering all matching routes. */}
      <Routes>
        <Route exact path='/' element={<ul>
          <br />
          <li>
            {/* Link component uses the to prop
            to describe the location where the
            links should navigate to. */}
            <Link to='/simple-interest' target='_blank'>
              Simple Interest
            </Link>
          </li>
        </ul>}>
        </Route>
        <Route exact path='/simple-interest' element={<SimpleInterest />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
